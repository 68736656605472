import { CSSProperties } from "react";

import {
  BodyMeasurementRequestDto,
  GarmentTypeDto,
  GenderTypeDto,
  FitPreferenceTypeDto,
  BodyShapeTypeDto,
  NudgeDto,
  ProductDetailDto,
} from "../swagger";
export type { BodyMeasurementRequestDto };
export { GarmentTypeDto };

export interface LoginDto {
  userName: string;
  password: string;
}

export interface ScreenOneDto {
  age: number;
  height: number;
  bodyWeight: number;
  gender: GenderTypeDto;
}

export interface ScreenTwoDto {
  bodyShape: BodyShapeTypeDto;
}

export interface ScreenThreeDto {
  fitPreference: FitPreferenceTypeDto;
}

export interface ScreenFourDto {
  chest: number;
  waist: number;
  hip: number;
  isBodyMeasured: boolean;
}

export enum ScreenTypeDto {
  ScreenOneBundle = 0,
  ScreenOne = 1,
  ScreenTwo = 2,
  ScreenThree = 3,
  Summary = 4,
  ScreenFour = 5,
}

export enum SizeTypeDto {
  SOUT = "SOUT",
  LOUT = "LOUT",
}

export enum MeasurementsTypeEnum {
  Chest = "chest",
  Waist = "waist",
  Hip = "hip",
  Length = "length",
}

export interface IconProps {
  className?: string;
  style?: CSSProperties;
}

export enum BrandConstants {
  BONITA = "BONITA", //We are handling cookies
  OHAPRIL = "OHAPRIL", //Need to be added by them
  TRIGEMA = "TRIGEMA", //They are handling
  RICHROYALDE = "RICH-ROYAL-DE", //Need to be added by them
  SAENGUINSUITS = "SAENGUINSUITS",
  TRIPLE2 = "TRIPLE2CYCLING-5975", //Need to be added by them
  KALYORA = "KALY-ORA",
  MARCOPOLO = "MOP", //They are handling
  TOMTAILOR = "TOMTAILOR", //We are handling cookies
  OUI = "OUI", //They are handling
  TOMASSO_BLACK = "TOMASSO-BLACK",
  JACKWOLFSKIN = "JACKWOLFSKIN", //We are handling cookies
  ETERNA = "ETERNA", //We are, They need to add us in Cookie
  SPREADSHIRT = "SPREADSHIRT",
  SCHOEFFEL = "SCHOEFFEL", //They are handling cookies
  DRYKORN = "DRYKORN", //There is No Cookie screen
  BETTY = "BETTY", //BEttybarclay
  ZERO = "ZERO", //BEttybarclay
  TFS = "TFS", //BEttybarclay
  SOLIVER = "SOLIVER",
  COMMA = "COMMA",
  TCHIBO = "TCHIBO",
  BOGNER = "BOGNER",
  FIREICE = "FIRE+ICE",
}

export interface SaizWidgetParams {
  brandCode: string;
  productCode: string;
  lang: string;
  visitorId: string;
}

export interface NudgeState {
  nudge: NudgeDto;
  brandCode?: string;
  productCode?: string;
  timestamp: Date;
}

export interface ProductState {
  product: ProductDetailDto;
  timestamp: Date;
  productCode: string;
}

export const BrandAnalytics: Record<string, number> = {
  [BrandConstants.MARCOPOLO]: 18,
  [BrandConstants.OHAPRIL]: 2,
  [BrandConstants.RICHROYALDE]: 3,
  [BrandConstants.ETERNA]: 4,
  [BrandConstants.TOMTAILOR]: 5,
  [BrandConstants.TRIPLE2]: 6,
  [BrandConstants.OUI]: 7,
  [BrandConstants.JACKWOLFSKIN]: 8,
  [BrandConstants.TRIGEMA]: 9,
  [BrandConstants.BONITA]: 10,
  [BrandConstants.DRYKORN]: 11,
  [BrandConstants.SCHOEFFEL]: 13,
  [BrandConstants.BETTY]: 15,
  [BrandConstants.ZERO]: 16,
  [BrandConstants.TFS]: 17,
};
