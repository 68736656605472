import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BrandConstants, ScreenTypeDto } from "../models";
import { ModalContext } from "../context/modalContext";
import { measurementsConfigDefault as measurementConfig } from "../config";

import {
  SaizMainContainer,
  SaizSlider,
  InchSlider,
  PoundSlider,
  SaizInputGroup,
  SaizUnitToggler,
} from "../components";
import { GenderTypeDto } from "../swagger";
import InfoButton from "../components/InfoButton";

function ScreenOne(props: any) {
  const { t } = useTranslation("common");
  const { lengthUnit, setLengthUnit, weightUnit, setWeightUnit } =
    useContext(ModalContext);
  const {
    data,
    onChange,
    onChangeValue,
    setStep,
    onIsBodyMeasured,
    brandCode,
    onEdit,
  } = props;

  const onSubmit = () => {
    onIsBodyMeasured(false);
    setStep(ScreenTypeDto.ScreenTwo);
  };
  const HeightSliderComponent = lengthUnit === "cm" ? SaizSlider : InchSlider;
  const WeightSliderComponent = weightUnit === "kg" ? SaizSlider : PoundSlider;

  return (
    <SaizMainContainer>
      <h2
        ref={(node: any) => {
          (brandCode === BrandConstants.SCHOEFFEL ||
            brandCode === BrandConstants.BOGNER ||
            brandCode === BrandConstants.FIREICE) &&
            node?.style.setProperty("font-size", "20px", "important");
        }}
        style={{
          textAlign: "center",
          position: "relative",
          marginBottom:
            brandCode === BrandConstants.OUI
              ? "40px"
              : brandCode === BrandConstants.TOMTAILOR ||
                brandCode === BrandConstants.JACKWOLFSKIN ||
                brandCode === BrandConstants.BETTY ||
                brandCode === BrandConstants.TFS ||
                brandCode === BrandConstants.ZERO ||
                brandCode === BrandConstants.BOGNER ||
                brandCode === BrandConstants.FIREICE ||
                brandCode === BrandConstants.TCHIBO
              ? "15px"
              : "unset",
        }}
        className={brandCode === BrandConstants.MARCOPOLO ? "uppercase" : ""}
      >
        {t("screenOne.heading")}
        {brandCode === BrandConstants.JACKWOLFSKIN && <InfoButton />}
      </h2>
      <form
        name="appForm"
        className="saiz-form"
        onSubmit={onSubmit}
        style={{ width: "100%" }}
      >
        <div>
          <p
            className="saiz_txt_gender"
            style={{
              color: "var(--saiz-colors-brand-500)",
              fontSize: "var(--saiz-fontSizes-xs)",
            }}
          >
            {t("screenOne.gender")}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "34px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {brandCode !== BrandConstants.BONITA &&
              brandCode !== BrandConstants.BETTY &&
              brandCode !== BrandConstants.TFS &&
              brandCode !== BrandConstants.ZERO && (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <input
                    type="radio"
                    name="gender"
                    id="male"
                    onChange={onChange}
                    value={GenderTypeDto.Male}
                    style={{
                      width: "20px",
                      height: "20px",
                      accentColor: "#000",
                      cursor: "pointer",
                    }}
                    checked={data.gender === GenderTypeDto.Male}
                  />
                  <label
                    id="male-label"
                    htmlFor="male"
                    style={{ fontSize: "16px", cursor: "pointer" }}
                  >
                    {t(GenderTypeDto.Male)}
                  </label>
                </div>
              )}
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <input
                type="radio"
                name="gender"
                id="female"
                onChange={onChange}
                value={GenderTypeDto.Female}
                style={{
                  width: "20px",
                  height: "20px",
                  accentColor: "#000",
                  cursor: "pointer",
                }}
                checked={data.gender === GenderTypeDto.Female}
              />
              <label
                id="female-label"
                htmlFor="female"
                style={{ fontSize: "16px", cursor: "pointer" }}
                onClick={onChange}
              >
                {t(GenderTypeDto.Female)}
              </label>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            maxWidth: "100%",
            marginTop: "10px",
            paddingTop: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SaizInputGroup
            value={data?.age}
            measurementUsed="age"
            title={t("screenOne.age")}
            start={measurementConfig["age"]}
            style={{ marginBottom: "10px" }}
            onChangeValue={onChangeValue}
            onChange={onChange}
            brandCode={brandCode}
          />
          <HeightSliderComponent
            value={data?.height}
            title={t("screenOne.height")}
            unit="cm"
            start={data?.height}
            minValue={measurementConfig["height"].min}
            maxValue={measurementConfig["height"].max}
            onChange={onChange}
            onChangeValue={onChangeValue}
            measurementUsed="height"
            style={{ marginTop: "2px", width: "100%" }}
          >
            <SaizUnitToggler
              brandCode={brandCode}
              value={lengthUnit}
              left="cm"
              right="ft"
              setValue={setLengthUnit as any}
            />
          </HeightSliderComponent>
          <WeightSliderComponent
            mt="-4px"
            value={data?.bodyWeight}
            title={t("screenOne.weight")}
            unit="kg"
            start={data?.bodyWeight}
            minValue={measurementConfig["weight"].min}
            maxValue={measurementConfig["weight"].max}
            onChange={onChange}
            onChangeValue={onChangeValue}
            measurementUsed="bodyWeight"
            style={{ marginTop: "5px" }}
          >
            <SaizUnitToggler
              brandCode={brandCode}
              value={weightUnit}
              left="kg"
              right="lbs"
              setValue={setWeightUnit as any}
            />
          </WeightSliderComponent>
        </div>
        <div
          style={{
            marginTop: "10px",
            textAlign: "center",
            gap: "10px",
            display:
              brandCode === BrandConstants.JACKWOLFSKIN ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{ color: "var(--saiz-colors-brand-500)", fontSize: "13px" }}
          >
            {t("summary.subHeading1")}
            <span
              style={{
                fontSize: "13px",
                padding: "4px 4px",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "600",
                color: "rgb(33, 33, 33)",
              }}
              onClick={() => {
                onEdit();
              }}
              id="btnEdit"
            >
              {t("edit")}
            </span>
          </p>
        </div>
      </form>
    </SaizMainContainer>
  );
}

export default ScreenOne;
