import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { measurementsConfigDefault } from "../config";
import { SaizImageLoader, SaizMainContainer, SaizSlider } from "../components";
import { BrandConstants } from "../models";
import ScreenThreeJW from "../components/ScreenThreeJW";
import InfoButton from "../components/InfoButton";

function ScreenThree(props: any) {
  const { t } = useTranslation("common");
  const { data, onChangeValue, brandCode, setStep } = props;

  useEffect(() => {
    if (brandCode === BrandConstants.MARCOPOLO) {
      setStep(4);
    }
  });

  return (
    <SaizMainContainer>
      <h2
        ref={(node: any) => {
          (brandCode === BrandConstants.SCHOEFFEL ||
            brandCode === BrandConstants.BOGNER ||
            brandCode === BrandConstants.FIREICE) &&
            node?.style.setProperty("font-size", "20px", "important");
        }}
        className={brandCode === BrandConstants.MARCOPOLO ? "uppercase" : ""}
        style={{
          color:
            brandCode === BrandConstants.TOMTAILOR
              ? "#001931"
              : brandCode === BrandConstants.TCHIBO
              ? "var(--saiz-widget-font-color)"
              : "#000",
          textAlign: "center",
          position: "relative",
        }}
      >
        {t("screenThree.heading")}
        {brandCode === BrandConstants.JACKWOLFSKIN && <InfoButton />}
      </h2>
      <div
        style={{
          minHeight: "300px",
          marginTop: "43px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "80%",
        }}
      >
        {brandCode === BrandConstants.JACKWOLFSKIN ? (
          <ScreenThreeJW />
        ) : (
          <SaizImageLoader
            item="weight.png"
            style={{
              width: "224px",
              marginBottom: "10px",
              marginTop: "28px",
              marginLeft: "2px",
            }}
            alt="weight"
          />
        )}

        <SaizSlider
          value={data?.exercise}
          title={t("screenThree.exercise")}
          unit={t("screenThree.hoursPerWeek")}
          onChangeValue={onChangeValue}
          minValue={measurementsConfigDefault.exercise.min}
          maxValue={measurementsConfigDefault.exercise.max}
          measurementUsed="exercise"
        />
      </div>
    </SaizMainContainer>
  );
}

export default ScreenThree;
