import { BrandConstants } from "../models";
import { createGlobalStyle } from "styled-components";

const commonCssProperties = {
  top: 28,
  color: "#49c03f",
  poistion: "fixed",
  zindex: 99999,
  screen_786_div_padding: "50px",
  background_radiant: `linear-gradient(
        104.9deg,
        rgba(107, 183, 225, 0.35) -4.71%,
        rgba(233, 109, 220, 0.35) 99.21%
      )`,
  screen_two_color: "#B989C5",
  screen_two_background: "rgba(185, 137, 197, 0.15)",
  saiz_link_desktop_font_weight: "initial",
  font: {
    header: `"Montserrat", sans-serif `,
    text: `"Montserrat", sans-serif `,
  },
  fontColor: "inherit",
  header: {
    text_transform: "uppercase",
  },
};

const getCssProperties = (theme: any) => {
  switch (theme.theme.brandCode) {
    case BrandConstants.TRIPLE2:
      return {
        ...commonCssProperties,
        zindex: 0,
      };
    case BrandConstants.OUI:
      return {
        ...commonCssProperties,
        top: 38,
        color: "#000000",
        font: {
          header: `Gotham Book`,
          text: `Chronicle`,
        },
      };
    case BrandConstants.RICHROYALDE:
      return {
        ...commonCssProperties,
        top: window.innerWidth <= 769 ? 55 : 38,
      };
    case BrandConstants.KALYORA:
      return {
        ...commonCssProperties,
        top: 38,
        screen_786_top: 38 + 7,
      };
    case BrandConstants.BONITA:
      return {
        ...commonCssProperties,
        top: 25,
        poistion: "relative",
        zindex: 0,
        screen_786_div_padding: "30px",
        background_radiant: `linear-gradient(
              104.9deg,
              rgba(0, 0, 0, 0.35) -4.71%,
              rgba(0, 0, 0, 0.35) 99.21%
            )`,
      };
    case BrandConstants.MARCOPOLO:
      return {
        ...commonCssProperties,
        screen_two_color: "#37A064",
        font: {
          header: `MOPGothic-Bold,Arial,sans-serif`,
          text: `MOPGothic-Regular,Helvetica Neue,Light`,
        },
        header: {
          text_transform: "uppercase",
        },
      };
    case BrandConstants.TOMTAILOR:
      return {
        ...commonCssProperties,
        screen_two_color: "#001931",
        color: "#001931",
        font: {
          header: `TTNorms`,
          text: `TTNorms`,
        },
        header: {
          text_transform: "uppercase",
        },
      };
    case BrandConstants.JACKWOLFSKIN:
      return {
        ...commonCssProperties,
        zindex: 10,
        screen_two_color: "#F9BA00",
        screen_two_background: "#F9BA0010",
        saiz_link_desktop_font_weight: "bold",
        font: {
          header: "Novera TT Modern",
          text: "JWS Roboto,sans-serif",
        },
      };
    case BrandConstants.TRIGEMA:
      return {
        ...commonCssProperties,
        top: 35,
      };
    case BrandConstants.ETERNA:
      return {
        ...commonCssProperties,
        color: "#71867C",
        screen_two_color: "#71867C",
        font: {
          header: "Gotham SSm A",
          text: "Gotham SSm A",
        },
      };
    case BrandConstants.BOGNER:
    case BrandConstants.FIREICE:
      return {
        ...commonCssProperties,
        screen_two_background: "#2c25b220",
        screen_two_color: "#2c25b2",
        font: {
          header: "Bogner,Arial,Helvetica,sans-serif",
          text: "Bogner,Arial,Helvetica,sans-serif",
        },
      };
    case BrandConstants.SCHOEFFEL:
      return {
        ...commonCssProperties,
        screen_two_color: "#006bd6",
        screen_two_background: "#006bd610",
        zindex: 10,
        font: {
          header: "KlavikaDisplayUltraBlack",
          text: "KlavikaWebBasic",
        },
      };
    case BrandConstants.DRYKORN:
      return {
        ...commonCssProperties,
        font: {
          header: "Flama",
          text: "Flama",
        },
      };
    case BrandConstants.BETTY:
    case BrandConstants.TFS:
    case BrandConstants.ZERO:
      return {
        ...commonCssProperties,
        screen_two_color: "#37A064",
        screen_two_background: "#37A06410",
        font: {
          header: "HKGroteskPro",
          text: "HKGroteskPro",
        },
      };
    case BrandConstants.SOLIVER:
      return {
        ...commonCssProperties,
        background_radiant: "#000000",
        screen_two_color: "#000000",
        screen_two_background: "#00000010",
        font: {
          header: "sOliverCaseTT",
          text: "sOliverCaseTT",
        },
      };
    case BrandConstants.COMMA:
      return {
        ...commonCssProperties,
        background_radiant: "#000000",
        screen_two_color: "#000000",
        screen_two_background: "#00000010",
        font: {
          header: "Gotham",
          text: "Gotham",
        },
      };
    case BrandConstants.TCHIBO:
      return {
        ...commonCssProperties,
        font: {
          header: "Interstate",
          text: "Interstate",
        },
        fontColor: "#21292F",
      };
    default:
      return commonCssProperties;
  }
};

export const GlobalStyles = createGlobalStyle`
    :root {
    --saiz-widget-buttonColor: ${(props: any) => getCssProperties(props).color};
    --saiz-widget-background-radiant: ${(props: any) =>
      getCssProperties(props).background_radiant};
    --saiz-widget-buttonTop: ${(props: any) => getCssProperties(props).top}%;
    --saiz-widget-nudgeTop: ${(props: any) => getCssProperties(props).top + 7}%;
    --saiz-widget-position: ${(props: any) => getCssProperties(props).poistion};
    --saiz-widget-zindex: ${(props: any) => getCssProperties(props).zindex};
    --saiz-widget-screen-768-div-padding: ${(props: any) =>
      getCssProperties(props).screen_786_div_padding};
    --saiz-widget-screen-two-color: ${(props: any) =>
      getCssProperties(props).screen_two_color};
    --saiz-widget-screen-two-background: ${(props: any) =>
      getCssProperties(props).screen_two_background};
    --saiz-widget-font-family-header: ${(props: any) =>
      getCssProperties(props).font.header};
    --saiz-widget-font-family-text: ${(props: any) =>
      getCssProperties(props).font.text};
    --saiz-widget-font-color: ${(props: any) =>
      getCssProperties(props).fontColor};
    --saiz-widget-header-text-transform: ${(props: any) =>
      getCssProperties(props).header.text_transform};
    --saiz_link_desktop_font_weight: ${(props: any) =>
      getCssProperties(props).saiz_link_desktop_font_weight};
    };
   
    @font-face {
      font-family: MOPGothic-Bold,Helvetica Neue,Light;
      src: local("MOPGothic-Bold"),
       url("./fonts/MOPGothic-Bold.ttf") format("truetype");
     }
     @font-face {
      font-family: MOPGothic-Regular,Helvetica Neue,Light;
      src: local("MOPGothic-Regular"),
       url("./fonts/MOPGothic-Regular.ttf") format("truetype");
     }
  `;
