import { CalculatorConstants } from "../constants";

const calculatorState = {
  loading: false,
  data: {},
  error: "",
  isLoaded: false,
};

export function CalculatorReducer(state = calculatorState, action: any) {
  switch (action.type) {
    case CalculatorConstants.CLEAR_BODY_MEASUREMENT:
      return { loading: false, isLoaded: false };
    case CalculatorConstants.GET_BODY_MEASUREMENT_REQUEST:
      return { loading: true };
    case CalculatorConstants.GET_BODY_MEASUREMENT_NOSIZE:
      return { loading: false };
    case CalculatorConstants.GET_BODY_MEASUREMENT_SUCCESS:
      return {
        loading: false,
        data: action.data,
        isLoaded: true,
        clear: false,
      };
    case CalculatorConstants.GET_BODY_MEASUREMENT_FAILURE:
      return { loading: true, error: action.error, isLoaded: false };
    case CalculatorConstants.SAVE_BASKET_SESSION_REQUEST:
      return { ...state, loading: true };
    case CalculatorConstants.SAVE_BASKET_SESSION_SUCCESS:
      return { ...state, loading: false };
    case CalculatorConstants.SAVE_BASKET_SESSION_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
export const calculatorSelector = (state: any) => state.calculatorState;
