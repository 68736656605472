import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BodyShapeTypeDto, GenderTypeDto } from "../swagger";
import { SaizImageLoader, SaizMainContainer, SaizType } from "../components";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { BrandConstants } from "../models";
import InfoButton from "../components/InfoButton";
import { useSwipeable } from "react-swipeable";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

function ScreenTwo(props: any) {
  const { t } = useTranslation("common");
  const arrowRight = "right_arrow_big.png";
  const arrowLeft = "left_arrow_big.png";

  const { onChange, auth, brandCode } = props;
  const [selected, setSelected] = useState<BodyShapeTypeDto | null>(
    auth?.localStorage.bodyShape
  );
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (
        sliderIndex! <
        Object.keys(gender === GenderTypeDto.Female ? itemsWoman : itemsMan)
          .length -
          1
      ) {
        changeSliderIndex(1);
      }
    },
    onSwipedRight: () => {
      if (sliderIndex! > 0) {
        changeSliderIndex(-1);
      }
    },
  });
  const itemsMan = [
    {
      index: 0,
      shape: BodyShapeTypeDto.VShape,
      title: t("screenTwo.VShape"),
      gender: "man",
      type: "apple",
      desc: t("screenTwo.VShapeText"),
    },
    {
      index: 1,
      shape: BodyShapeTypeDto.Rectangle,
      title:
        brandCode === BrandConstants.TCHIBO
          ? t("screenTwo.RectangleMale")
          : t("screenTwo.Rectangle"),
      gender: "man",
      type: "rectangle",
      desc:
        brandCode === BrandConstants.TCHIBO
          ? t("screenTwo.RectangleMaleText")
          : t("screenTwo.RectangleText"),
    },
    {
      index: 2,
      shape: BodyShapeTypeDto.Triangle,
      title: t("screenTwo.Triangle"),
      gender: "man",
      type: "pear",
      desc: t("screenTwo.TriangleText"),
    },
  ];

  const itemsWoman = [
    {
      index: 0,
      shape: BodyShapeTypeDto.Hourglass,
      title: t("screenTwo.Hourglass"),
      gender: "woman",
      type: "hourglass",
      desc: t("screenTwo.HourglassText"),
    },
    {
      index: 1,
      shape: BodyShapeTypeDto.Apple,
      title: t("screenTwo.Apple"),
      gender: "woman",
      type: "apple",
      desc: t("screenTwo.AppleText"),
    },
    {
      index: 2,
      shape: BodyShapeTypeDto.Pear,
      title: t("screenTwo.Pear"),
      gender: "woman",
      type: "pear",
      desc: t("screenTwo.PearText"),
    },
    {
      index: 3,
      shape: BodyShapeTypeDto.Rectangle,
      title: t("screenTwo.Rectangle"),
      gender: "woman",
      type: "rectangle",
      desc: t("screenTwo.RectangleText"),
    },
  ];

  const selectedSliderIndexMobile = () => {
    let ind: number;
    const type: string = auth?.localStorage?.bodyShape;
    try {
      const itemsGender =
        auth.localStorage.gender === GenderTypeDto.Female
          ? itemsWoman
          : itemsMan;
      const findIndex = itemsGender.find(
        (item) => item.type === type?.toLowerCase()
      );
      ind = findIndex!.index;
    } catch (err) {
      ind = 1;
    }
    return ind;
  };
  function capitalizeFirstLetter(arg: string) {
    return arg.charAt(0).toUpperCase() + arg.slice(1);
  }

  const findTypeSliderIndex = (arg: number) => {
    let ind: string;
    try {
      const itemsGender =
        auth.localStorage.gender === GenderTypeDto.Female
          ? itemsWoman
          : itemsMan;
      const findIndex = itemsGender.find((item) => item.index === arg);
      ind = capitalizeFirstLetter(findIndex!.type);
    } catch (err) {
      ind = "Pear";
    }
    return ind;
  };

  const [sliderIndex, setSliderIndex] = useState<number>(
    selectedSliderIndexMobile()
  );
  const [gender] = useState<GenderTypeDto>(auth.localStorage.gender);

  const changeBodyType = (type: BodyShapeTypeDto) => {
    setSelected(type);
    onChange(type);
  };

  const renderShapes = (gender: string) => {
    const shapes = renderMobile(gender);
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 10);
    return (
      <div {...handlers} className="saiz-shapes-slider" key={small_id}>
        {shapes}
      </div>
    );
  };

  const renderShapesDesktop = (gender: string) => {
    const shapes = renderDesktop(gender);
    const unique_id = uuid();
    const small_id = unique_id.slice(0, 9);
    return (
      <div className="saiz-shapes-slider-desktop" key={small_id}>
        {shapes}
      </div>
    );
  };

  const renderMobile = (gender: string) => {
    const itemsGender = gender === GenderTypeDto.Female ? itemsWoman : itemsMan;
    const lowerBound = sliderIndex! > 0 ? sliderIndex! - 1 : sliderIndex;
    const clipped: any[] = itemsGender.slice(lowerBound!, sliderIndex! + 2);

    if (sliderIndex! + 1 === itemsGender.length) {
      clipped.push({ type: "dummy", key: "100" });
    }
    if (sliderIndex === 0) {
      clipped.unshift({ type: "dummy", key: "-100" });
    }

    const items = clipped.map((item, index) => {
      let unique_id = uuid();
      const div_id = unique_id.slice(0, 9);
      unique_id = uuid();
      const oka_id = unique_id.slice(0, 7);
      if (item.type === "dummy") {
        return <div className="unzoomed" key={div_id}></div>;
      }
      return (
        <div
          onClick={() => {
            if (sliderIndex !== item.index) {
              changeBodyType(item.shape);
              sliderIndex! < item.index
                ? changeSliderIndex(1)
                : changeSliderIndex(-1);
            }
          }}
          key={oka_id}
          className={`${sliderIndex === item.index ? "zoomed" : "unzoomed"}`}
        >
          <SaizType
            className={`${sliderIndex === item.index ? "selected" : ""}`}
            title={item.title}
            gender={item.gender}
            type={item.type}
            desc={item.desc}
          ></SaizType>
        </div>
      );
    });

    return <>{items}</>;
  };

  const renderDesktop = (gender: string) => {
    const itemsGender = gender === GenderTypeDto.Female ? itemsWoman : itemsMan;
    const unique_id = uuid();
    const div_id = unique_id.slice(0, 11);
    const items = itemsGender.map((item: any) => {
      return (
        <div
          onClick={() => changeBodyType(item.shape)}
          key={`${div_id}_${item.title}`}
          style={{
            maxWidth: `calc(100% / ${itemsGender.length})`,
          }}
        >
          <SaizType
            className={`${selected === item.shape ? "selected" : ""}`}
            title={item.title}
            gender={item.gender}
            type={item.type}
            desc={item.desc}
          ></SaizType>
        </div>
      );
    });

    return <>{items}</>;
  };

  const changeSliderIndex = (arg: number) => {
    const selectedType: string = findTypeSliderIndex(sliderIndex! + arg);
    changeBodyType(selectedType as BodyShapeTypeDto);
    setSliderIndex((prev) => {
      return prev! + arg;
    });
  };

  return (
    <SaizMainContainer>
      <h2
        style={{
          textAlign: "center",
          position: "relative",
          marginBottom: brandCode === BrandConstants.OUI ? "3em" : "unset",
        }}
        ref={(node: any) => {
          (brandCode === BrandConstants.SCHOEFFEL ||
            brandCode === BrandConstants.BOGNER ||
            brandCode === BrandConstants.FIREICE) &&
            node?.style.setProperty("font-size", "20px", "important");
        }}
        className={brandCode === BrandConstants.MARCOPOLO ? "uppercase" : ""}
      >
        {t("screenTwo.heading")}
        {brandCode === BrandConstants.JACKWOLFSKIN && <InfoButton />}
      </h2>
      <Wrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <SaizImageLoader
            className={
              sliderIndex! <= 0
                ? "saiz-caret-disabled arrowLeft"
                : "saiz-caret arrowLeft"
            }
            item={arrowLeft}
            style={{ width: "15px" }}
            onClick={() => {
              if (sliderIndex! > 0) {
                changeSliderIndex(-1);
              }
            }}
            alt="prev"
          />

          {renderShapes(gender)}
          {renderShapesDesktop(gender)}

          <SaizImageLoader
            className={
              sliderIndex! <
              Object.keys(
                gender === GenderTypeDto.Female ? itemsWoman : itemsMan
              ).length -
                1
                ? "saiz-caret arrowRight"
                : "saiz-caret-disabled arrowRight"
            }
            item={arrowRight}
            style={{ width: "15px" }}
            onClick={() => {
              if (
                sliderIndex! <
                Object.keys(
                  gender === GenderTypeDto.Female ? itemsWoman : itemsMan
                ).length -
                  1
              ) {
                changeSliderIndex(1);
              }
            }}
            alt="next"
          />
        </div>
        <p
          style={{
            fontSize: "14px",
            color:
              brandCode === BrandConstants.TOMTAILOR
                ? "#001931"
                : brandCode === BrandConstants.TCHIBO
                ? "var(--saiz-widget-font-color)"
                : "var(--saiz-colors-brand-1000)",
            fontWeight: "600",
            textAlign: "center",
            maxWidth: brandCode === BrandConstants.OUI ? "60%" : "unset",
          }}
        >
          {t("screenTwo.subHeading")}
        </p>
      </Wrapper>
    </SaizMainContainer>
  );
}
const mapStateToProps = (state: any, ownProps: any) => {
  return {
    auth: state.AuthReducer,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(ScreenTwo);
